// https://date-fns.org/docs/format
// import format from 'date-fns/format'
import moment from "moment/moment";
// import store from '@state/store'
// import { zhTW, enUS } from 'date-fns/locale'
// const { formatToTimeZone } = require('date-fns-timezone')

// const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Asia/Taipei';
/* const langList = {
  "zh-TW": zhTW,
  "en": enUS,
}; */
moment.locale('en');
const timezone = 0 - (new Date().getTimezoneOffset() / 60)

// timeZone
// D.M.YYYY HH:mm:ss.SSS [GMT]Z (z)
// 1.9.2018 18:01:36.386 GMT+02:00 (CEST)

export default function formatDate(date) {
  /* return formatToTimeZone(date, 'YYYY-MM-DD', {
    timeZone: 'Europe/Berlin',
  }) // '2019-06-13' */
  return moment(date).add(timezone, 'hours').format("YYYY-MM-DD");
}

export function formatTimeLineDate(date) {
  /* return formatToTimeZone(date, 'YYYY MMM.', {
    timeZone: 'Europe/Berlin',
  }) // '2019 Aug.' */
  return moment(date).add(timezone, 'hours').format("YYYY MMM.");
}

export function formatNewDate(date) {
  /* return formatToTimeZone(date, 'MMM DD.YYYY', {
    timeZone: 'Europe/Berlin',
  }) // 'Aug 18.2019' */
  return moment(date).add(timezone, 'hours').format("MMM DD.YYYY");
}

export function formatNewItemDate(date) {
  /* return formatToTimeZone(date, 'YYYY.MM.DD', {
    timeZone: 'Europe/Berlin',
  }) // '2019.01.01' */
  return moment(date).add(timezone, 'hours').format("YYYY.MM.DD");
}
